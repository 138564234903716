import { gsap } from 'plugged-gsap'
import { useEffect, type RefObject } from 'react'

export const useTicker = ({
  firstRowRef,
  secondRowRef,
  startPosition = 100,
  speed = 100
}: {
  firstRowRef: RefObject<HTMLDivElement>
  secondRowRef: RefObject<HTMLDivElement>
  startPosition?: number
  speed?: number
}): void => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      const duration = firstRowRef.current!.clientWidth / speed

      let width = Array.from(firstRowRef.current!.children).reduce((acc, child) => acc + child.clientWidth + 6, 0)

      gsap.fromTo(
        firstRowRef.current,
        {
          x: -startPosition
        },
        {
          x: firstRowRef.current ? -(startPosition + width / 2) : 0,
          duration: duration,
          repeat: -1,
          ease: 'none'
        }
      )
      gsap.fromTo(
        secondRowRef.current,
        {
          x: startPosition
        },
        {
          x: secondRowRef.current ? width / 2 + startPosition : 0,
          duration: duration,
          repeat: -1,
          ease: 'none'
        }
      )
    })

    return () => {
      ctx.revert()
    }
  })
}
