import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Category, type Case } from 'types'
import { ServiceId } from './our-services'

export const enum CaseStudyEnum {
  AiTutor = 'ai-tutor',
  Mural = 'collaboration-tool-for-enterprises',
  GridCapital = 'grid-capital',
  Smartcat = 'smartcat',
  LevelUpBasketball = 'level-up-basketball',
  Ameetee = 'ameetee',
  HrRocker = 'hr-rocket',
  BeachIo = 'beach-io',
  PaloSanto = 'palo-santo',
  Forge = 'forge',
  HyperAd = 'hyper-ad'
}
export const caseStudies: Record<CaseStudyEnum, Case> = {
  [CaseStudyEnum.Smartcat]: {
    id: CaseStudyEnum.Smartcat,
    title: 'Smartcat: AI-powered Translation and Localization SaaS Platform',
    about: `Smartcat is a cloud-based platform that's shaking up how enterprises handle translation and localization. It provides a secure, private workspace for each enterprise to manage and store multilingual content, which serves as a dynamic company-wide library.`,
    categories: [Category.Saas, Category.B2B],
    services: [ServiceId.Frontend, ServiceId.Backend],
    image: <StaticImage class="w-full h-full" src="../../images/case-smartcat.png" alt="Smartcat" />,
    client: 'Smartcat',
    year: '2024 - Ongoing',
    websiteUrl: 'https://www.smartcat.com/'
  },
  [CaseStudyEnum.Ameetee]: {
    id: CaseStudyEnum.Ameetee,
    title: 'B2B Fintech Platform for Private Investments',
    about: `Ameetee is a white label, plug and play investment marketplace for Financial Institutions aiming to provide their clients with seamless, risk-adjusted access to private market investments.`,
    categories: [Category.B2B, Category.Angular, Category.Django, Category.Python],
    services: [ServiceId.Frontend, ServiceId.Backend],
    image: <StaticImage class="w-full h-full" src="../../images/case-ameetee.png" alt="Ameetee" />,
    client: 'Ameetee',
    year: '2023 - Ongoing',
    websiteUrl: 'https://ameetee.io/'
  },
  [CaseStudyEnum.LevelUpBasketball]: {
    id: CaseStudyEnum.LevelUpBasketball,
    title: 'We Mapped Every Court on Earth for Level Up Basketball',
    about: `With our friends at Level Up Basket we kicked off an ambitious project with a goal to make it easier for people to find and access basketball courts nearby. We wanted to improve the way basketball fans could connect and play together.`,
    categories: [Category.B2C, Category.B2B, Category.Mobile, Category.RubyOnRails],
    services: [ServiceId.Frontend, ServiceId.Backend],
    image: <StaticImage class="w-full h-full" src="../../images/case-level-up-basketball.png" alt="Level Up Basket" />,
    client: 'Level Up Basketball',
    year: '2023 - Ongoing',
    websiteUrl: 'https://www.levelupbasket.com/'
  },
  [CaseStudyEnum.Mural]: {
    id: CaseStudyEnum.Mural,
    title: 'NDA Project: Collaboration Tool for Enterprises',
    about: `This project is an innovative solution for facilitating online working meetings. The client wanted to provide users with a product that enables the creation and management of collaborative meetings, incorporating dynamic forms and seamless integration with popular calendar applications. This case study shows into the challenges Cozy Ventures faced during the project's development and the strategic approaches employed to overcome them.`,
    categories: [Category.Saas, Category.Enterprise, Category.React, Category.Rails],
    services: [ServiceId.Frontend, ServiceId.Backend],
    image: (
      <StaticImage class="w-full h-full" src="../../images/case-collaboration-tool.png" alt="Collaboration Tool" />
    ),
    client: 'NDA',
    year: '2021 - Ongoing',
    websiteUrl: null
  },
  [CaseStudyEnum.GridCapital]: {
    id: CaseStudyEnum.GridCapital,
    title: 'Building AI Assistant for Innovative Trading Platform',
    about: `Ever wondered how trading companies stay on top of the latest news and market trends? Well, here’s a little peek behind the curtain at Cozy Ventures, where we tackled just that challenge for Grid Capital Inc. Our goal was simple yet ambitious: create an AI buddy that’s not just smart, but also super helpful in sorting through vast amounts of information and making sense of it all.`,
    categories: [Category.B2C, Category.Python],
    services: [ServiceId.Backend, ServiceId.MachineLearning],
    image: <StaticImage class="w-full h-full" src="../../images/case-grid-capital.png" alt="Grid Capital" />,
    client: 'Grid Capital',
    year: '2023',
    websiteUrl: 'https://gridcap.us'
  },
  [CaseStudyEnum.PaloSanto]: {
    id: CaseStudyEnum.PaloSanto,
    title: 'Investing in Psychedelics: Palo Santo VC Website',
    about: `We had the privilege of collaborating with Palo Santo, a venture capital fund focused on backing and building emerging psychedelic therapeutics companies. Palo Santo invests in a diverse range of businesses developing innovative psychedelic medicines and therapies targeting for mental health and various central nervous system disorders.`,
    categories: [],
    services: [ServiceId.Frontend, ServiceId.Backend],
    image: <StaticImage class="w-full h-full" src="../../images/case-palo-santo.png" alt="Palo Santo" />,
    client: 'Palo Santo',
    year: '2021',
    websiteUrl: 'https://palosanto.vc'
  },
  [CaseStudyEnum.HyperAd]: {
    id: CaseStudyEnum.HyperAd,
    title: 'Audit for Mobile Advertising Platform',
    about: `Cozy Ventures went on a roll with a structured IT audit for Hyper Ad to address various operational challenges within their advertising technology platform. The audit commenced with detailed planning, focusing on several key areas critical to the platform's success and compliance.`,
    categories: [Category.B2C, Category.AdTech],
    services: [ServiceId.ProductAudit],
    image: <StaticImage class="w-full h-full" src="../../images/case-hyper-ad/case-hyper-ad.png" alt="HyperAd" />,
    client: 'HyperAd',
    year: '2024',
    websiteUrl: 'https://hyperad.tech/main-eng/'
  },
  [CaseStudyEnum.HrRocker]: {
    id: CaseStudyEnum.HrRocker,
    title: 'Building Organizational Charts on Canvas',
    about: `HR-Rocket is a smart platform that uses machine learning algorithms to optimize media planning and benchmarking, enabling companies to hire more employees without increasing budgets. The platform is specifically built to address the biggest pain points in HR marketing, taking on the burden of mass recruitment and cost reduction in hiring.`,
    categories: [Category.B2B, Category.Python, Category.Research, Category.Vue],
    services: [ServiceId.Frontend, ServiceId.MachineLearning],
    image: <StaticImage class="w-full h-full" src="../../images/case-hr-rocket.jpeg" alt="HR Rocket" />,
    client: 'HR-Rocket',
    year: '2023',
    websiteUrl: null
  },
  [CaseStudyEnum.BeachIo]: {
    id: CaseStudyEnum.BeachIo,
    title: 'Partnership with Beach Digital',
    about: `We’ve been partnering with beach.io for almost a decade, united by a shared dedication to innovation and creating value. We've seen beach.io's products grow and evolve, blending human-centered approaches with the latest technologies. Here, we would love to name a few projects that we had the privilege of working on:`,
    categories: [],
    services: [ServiceId.Frontend, ServiceId.Backend, ServiceId.Design, ServiceId.MachineLearning],
    image: <StaticImage class="w-full h-full" src="../../images/case-beach-io.png" alt="Beach.io" />,
    client: 'Beach',
    year: '2015 - Ongoing',
    websiteUrl: 'https://beach.io/'
  },
  [CaseStudyEnum.Forge]: {
    id: CaseStudyEnum.Forge,
    title: 'Hosting Platform for Indie Devs and Startups',
    about: `Initially conceived as a static site hosting platform, Forge has metamorphosed into a comprehensive SaaS solution, pioneering the Extensibility (E11Y) first design philosophy. It empowers technology companies to establish vibrant plugin marketplaces and developer ecosystems seamlessly integrated with their SaaS or eCommerce offerings. Forge is all about accelerating digital experiences to the speed of thought.`,
    categories: [Category.Saas, Category.Ecommerce, Category.B2B],
    services: [ServiceId.Backend],
    image: <StaticImage class="w-full h-full" src="../../images/case-forge/case-forge.png" alt="Forge" />,
    squareImage: <StaticImage class="w-full h-full" src="../../images/case-forge/case-forge-square.png" alt="Forge" />,
    client: 'Beach',
    year: '2015 - Ongoing',
    websiteUrl: 'https://new.getforge.com/'
  },
  [CaseStudyEnum.AiTutor]: {
    id: CaseStudyEnum.AiTutor,
    title: 'R&D for AI Language Tutor Application',
    about: `A client from International High School approached Cozy Ventures to develop an iOS application that consolidates various educational tools to enhance IELTS preparation. This app is designed to integrate speaking, writing, and grammar practice functionalities into a single platform using AI technologies.`,
    categories: [Category.B2C, Category.RnD],
    services: [ServiceId.MachineLearning],
    image: <StaticImage class="w-full h-full" src="../../images/case-ai-tutor.png" alt="AI Tutor" />,
    client: 'NDA',
    year: '2024',
    websiteUrl: null
  }
}

export const caseStudiesList = Object.keys(caseStudies).map(key => caseStudies[key as CaseStudyEnum])
