exports.components = {
  "component---src-components-blog-tsx": () => import("./../../../src/components/blog.tsx" /* webpackChunkName: "component---src-components-blog-tsx" */),
  "component---src-components-page-blog-article-tsx": () => import("./../../../src/components/page/blog/Article.tsx" /* webpackChunkName: "component---src-components-page-blog-article-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-case-studies-ai-tutor-tsx": () => import("./../../../src/pages/case-studies/ai-tutor.tsx" /* webpackChunkName: "component---src-pages-case-studies-ai-tutor-tsx" */),
  "component---src-pages-case-studies-ameetee-tsx": () => import("./../../../src/pages/case-studies/ameetee.tsx" /* webpackChunkName: "component---src-pages-case-studies-ameetee-tsx" */),
  "component---src-pages-case-studies-beach-io-tsx": () => import("./../../../src/pages/case-studies/beach-io.tsx" /* webpackChunkName: "component---src-pages-case-studies-beach-io-tsx" */),
  "component---src-pages-case-studies-collaboration-tool-for-enterprises-tsx": () => import("./../../../src/pages/case-studies/collaboration-tool-for-enterprises.tsx" /* webpackChunkName: "component---src-pages-case-studies-collaboration-tool-for-enterprises-tsx" */),
  "component---src-pages-case-studies-forge-tsx": () => import("./../../../src/pages/case-studies/forge.tsx" /* webpackChunkName: "component---src-pages-case-studies-forge-tsx" */),
  "component---src-pages-case-studies-grid-capital-tsx": () => import("./../../../src/pages/case-studies/grid-capital.tsx" /* webpackChunkName: "component---src-pages-case-studies-grid-capital-tsx" */),
  "component---src-pages-case-studies-hr-rocket-tsx": () => import("./../../../src/pages/case-studies/hr-rocket.tsx" /* webpackChunkName: "component---src-pages-case-studies-hr-rocket-tsx" */),
  "component---src-pages-case-studies-hyper-ad-tsx": () => import("./../../../src/pages/case-studies/hyper-ad.tsx" /* webpackChunkName: "component---src-pages-case-studies-hyper-ad-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-level-up-basketball-tsx": () => import("./../../../src/pages/case-studies/level-up-basketball.tsx" /* webpackChunkName: "component---src-pages-case-studies-level-up-basketball-tsx" */),
  "component---src-pages-case-studies-palo-santo-tsx": () => import("./../../../src/pages/case-studies/palo-santo.tsx" /* webpackChunkName: "component---src-pages-case-studies-palo-santo-tsx" */),
  "component---src-pages-case-studies-smartcat-tsx": () => import("./../../../src/pages/case-studies/smartcat.tsx" /* webpackChunkName: "component---src-pages-case-studies-smartcat-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-open-source-index-tsx": () => import("./../../../src/pages/open-source/index.tsx" /* webpackChunkName: "component---src-pages-open-source-index-tsx" */),
  "component---src-pages-services-backend-tsx": () => import("./../../../src/pages/services/backend.tsx" /* webpackChunkName: "component---src-pages-services-backend-tsx" */),
  "component---src-pages-services-design-tsx": () => import("./../../../src/pages/services/design.tsx" /* webpackChunkName: "component---src-pages-services-design-tsx" */),
  "component---src-pages-services-frontend-tsx": () => import("./../../../src/pages/services/frontend.tsx" /* webpackChunkName: "component---src-pages-services-frontend-tsx" */),
  "component---src-pages-services-full-cycle-tsx": () => import("./../../../src/pages/services/full-cycle.tsx" /* webpackChunkName: "component---src-pages-services-full-cycle-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-services-machine-learning-tsx": () => import("./../../../src/pages/services/machine-learning.tsx" /* webpackChunkName: "component---src-pages-services-machine-learning-tsx" */),
  "component---src-pages-services-product-audit-tsx": () => import("./../../../src/pages/services/product-audit.tsx" /* webpackChunkName: "component---src-pages-services-product-audit-tsx" */)
}

