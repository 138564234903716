export const contactFormHeroes = [
  {
    name: 'Elon Musk',
    company: 'Tesla',
    email: 'elon@tesla.com'
  },
  {
    name: 'Jeff Bezos',
    company: 'Amazon',
    email: 'jeff@amazon.com'
  },
  {
    name: 'Bill Gates',
    company: 'Microsoft',
    email: 'bill@microsoft.com'
  },
  {
    name: 'Mark Zuckerberg',
    company: 'Meta',
    email: 'zuck@meta.com'
  },
  {
    name: 'Warren Buffett',
    company: 'Berkshire Hathaway',
    email: 'wbuffett@berkshirehathaway.com'
  },
  {
    name: 'Larry Page',
    company: 'Google',
    email: 'larry.page@google.com'
  },
  {
    name: 'Sergey Brin',
    company: 'Google',
    email: 'sergey.brin@google.com'
  },
  {
    name: 'Tim Cook',
    company: 'Apple',
    email: 'tim.cook@apple.com'
  },
  {
    name: 'Satya Nadella',
    company: 'Microsoft',
    email: 'satya.nadella@microsoft.com'
  },
  {
    name: 'Jack Dorsey',
    company: 'Twitter',
    email: 'jack.dorsey@twitter.com'
  },
  {
    name: 'Richard Branson',
    company: 'Virgin Group',
    email: 'richard.branson@virgin.com'
  },
  {
    name: 'Larry Ellison',
    company: 'Oracle',
    email: 'larry.ellison@oracle.com'
  },
  {
    name: 'Michael Dell',
    company: 'Dell Technologies',
    email: 'michael@dell.com'
  },
  {
    name: 'Howard Schultz',
    company: 'Starbucks',
    email: 'howard@starbucks.com'
  },
  {
    name: 'Reed Hastings',
    company: 'Netflix',
    email: 'r.hastings@netflix.com'
  },
  {
    name: 'Steve Jobs',
    company: 'Apple',
    email: 'steve@apple.com'
  },
  {
    name: 'Sheryl Sandberg',
    company: 'Facebook',
    email: 'sheryl.sandberg@facebook.com'
  },
  {
    name: 'Eric Schmidt',
    company: 'Google',
    email: 'eric@google.com'
  },
  {
    name: 'Peter Thiel',
    company: 'PayPal',
    email: 'p.thiel@paypal.com'
  },
  {
    name: 'Marc Benioff',
    company: 'Salesforce',
    email: 'marc.benioff@salesforce.com'
  },
  {
    name: 'Leonardo DiCaprio',
    company: 'Appian Way Productions',
    email: 'leonardo.dicaprio@appianway.com'
  },
  {
    name: 'Scarlett Johansson',
    company: 'Creative Artists Agency',
    email: 'scarlett.johansson@caa.com'
  },
  {
    name: 'Denzel Washington',
    company: 'William Morris Endeavor',
    email: 'denzel.washington@wme.com'
  },
  {
    name: 'Meryl Streep',
    company: 'ICM Partners',
    email: 'meryl.streep@icm.com'
  },
  {
    name: 'Tom Hanks',
    company: 'United Talent Agency',
    email: 'tom.hanks@uta.com'
  },
  {
    name: 'Jennifer Lawrence',
    company: 'Creative Artists Agency',
    email: 'jennifer.lawrence@caa.com'
  },
  {
    name: 'Brad Pitt',
    company: 'William Morris Endeavor',
    email: 'brad.pitt@wme.com'
  },
  {
    name: 'Angelina Jolie',
    company: 'United Talent Agency',
    email: 'angelina.jolie@uta.com'
  },
  {
    name: 'Robert Downey Jr.',
    company: 'Creative Artists Agency',
    email: 'robert.downey@caa.com'
  },
  {
    name: 'Natalie Portman',
    company: 'William Morris Endeavor',
    email: 'natalie.portman@wme.com'
  },
  {
    name: 'George Clooney',
    company: 'Creative Artists Agency',
    email: 'george.clooney@caa.com'
  },
  {
    name: 'Julia Roberts',
    company: 'Creative Artists Agency',
    email: 'julia.roberts@caa.com'
  },
  {
    name: 'Dwayne Johnson',
    company: 'William Morris Endeavor',
    email: 'dwayne.johnson@wme.com'
  },
  {
    name: 'Emma Stone',
    company: 'United Talent Agency',
    email: 'emma.stone@uta.com'
  },
  {
    name: 'Johnny Depp',
    company: 'United Talent Agency',
    email: 'johnny.depp@uta.com'
  },
  {
    name: 'Sandra Bullock',
    company: 'Creative Artists Agency',
    email: 'sandra.bullock@caa.com'
  },
  {
    name: 'Will Smith',
    company: 'Creative Artists Agency',
    email: 'will.smith@caa.com'
  },
  {
    name: 'Nicole Kidman',
    company: 'Creative Artists Agency',
    email: 'nicole.kidman@caa.com'
  },
  {
    name: 'Chris Hemsworth',
    company: 'Creative Artists Agency',
    email: 'chris.hemsworth@caa.com'
  },
  {
    name: 'Charlize Theron',
    company: 'Creative Artists Agency',
    email: 'charlize.theron@caa.com'
  },
  {
    name: 'Paul McCartney',
    company: 'The Beatles',
    email: 'paul@beatles.com'
  },
  {
    name: 'Mick Jagger',
    company: 'The Rolling Stones',
    email: 'mick.jagger@rollingstones.com'
  },
  {
    name: 'Beyoncé',
    company: "Destiny's Child",
    email: 'beyonce@beyonce.com'
  },
  {
    name: 'Bruce Springsteen',
    company: 'E Street Band',
    email: 'bruce.springsteen@e-streetband.com'
  },
  {
    name: 'Freddie Mercury',
    company: 'Queen',
    email: 'freddie@queen.com'
  },
  {
    name: 'Kurt Cobain',
    company: 'Nirvana',
    email: 'kurt@nirvana.com'
  },
  {
    name: 'Adele',
    company: 'Solo',
    email: 'adele@adele.com'
  },
  {
    name: 'Elton John',
    company: 'Solo',
    email: 'elton.john@eltonjohn.com'
  },
  {
    name: 'Madonna',
    company: 'Solo',
    email: 'madonna@madonna.com'
  },
  {
    name: 'Michael Jackson',
    company: 'The Jackson 5',
    email: 'm.jackson@michaeljackson.com'
  },
  {
    name: 'David Bowie',
    company: 'Solo',
    email: 'david@bowie.com'
  },
  {
    name: 'Whitney Houston',
    company: 'Solo',
    email: 'whitney.houston@whitneyhouston.com'
  },
  {
    name: 'Prince',
    company: 'Solo',
    email: 'prince@prince.com'
  },
  {
    name: 'Rihanna',
    company: 'Solo',
    email: 'rihanna@rihanna.com'
  },
  {
    name: 'Taylor Swift',
    company: 'Solo',
    email: 'taylor.swift@taylorswift.com'
  },
  {
    name: 'Drake',
    company: 'Solo',
    email: 'drake@drake.com'
  },
  {
    name: 'Kanye West',
    company: 'Solo',
    email: 'ye@west.com'
  },
  {
    name: 'Justin Bieber',
    company: 'Solo',
    email: 'justin.bieber@justinbieber.com'
  },
  {
    name: 'Yuri Gagarin',
    company: 'Roscosmos',
    email: 'yuri.gagarin@cosmos.com'
  },
  {
    name: 'Lev Tolstoy',
    company: 'Author',
    email: 'lev.tolstoy@literature.com'
  },
  {
    name: 'Fyodor Dostoevsky',
    company: 'Author',
    email: 'dostoevsky@literature.com'
  },
  {
    name: 'Pavel Durov',
    company: 'Telegram',
    email: 'pavel@telegram.org'
  },
  {
    name: 'Andrey Rublev',
    company: 'Icon Painter',
    email: 'andrey.rublev@art.com'
  },
  {
    name: 'Andrei Tarkovsky',
    company: 'Film Director',
    email: 'tarkovsky@films.com'
  },
  {
    name: 'Dmitry Shostakovich',
    company: 'Composer',
    email: 'shostakovich@music.com'
  },
  {
    name: 'Vladimir Nabokov',
    company: 'Author',
    email: 'vladimir.nabokov@literature.com'
  },
  {
    name: 'Igor Stravinsky',
    company: 'Composer',
    email: 'igor.stravinsky@music.com'
  },
  {
    name: 'Sergei Rachmaninoff',
    company: 'Composer',
    email: 'sergei.rachmaninoff@music.com'
  },
  {
    name: 'Albert Hofmann',
    company: 'Sandoz Pharmaceuticals',
    email: 'albert.hofmann@sandoz.com'
  },
  {
    name: 'Timothy Leary',
    company: 'Harvard University',
    email: 'timothy.leary@harvard.edu'
  },
  {
    name: 'Alexander Shulgin',
    company: 'Solo',
    email: 'alexander.shulgin@shulgin.com'
  },
  {
    name: 'Ann Shulgin',
    company: 'Solo',
    email: 'ann.shulgin@shulgin.com'
  },
  {
    name: 'James Charles',
    company: 'MrBeast',
    email: 'james@mrbeast.com'
  },
  {
    name: 'Felix Kjellberg',
    company: 'YouTube',
    email: 'pewdiepie@youtube.com'
  },
  {
    name: 'Travis Kalanick',
    company: 'Uber',
    email: 'travis.kalanick@uber.com'
  },
  {
    name: 'Evan Spiegel',
    company: 'Snapchat',
    email: 'evan.spiegel@snapchat.com'
  },
  {
    name: 'Drew Houston',
    company: 'Dropbox',
    email: 'drew.houston@dropbox.com'
  },
  {
    name: 'Daniel Ek',
    company: 'Spotify',
    email: 'daniel.ek@spotify.com'
  },
  {
    name: 'Ben Silbermann',
    company: 'Pinterest',
    email: 'ben.silbermann@pinterest.com'
  },
  {
    name: 'Kevin Systrom',
    company: 'Instagram',
    email: 'kevin.systrom@instagram.com'
  },
  {
    name: 'Stewart Butterfield',
    company: 'Slack',
    email: 'stewart.butterfield@slack.com'
  },
  {
    name: 'Patrick Collison',
    company: 'Stripe',
    email: 'patrick.collison@stripe.com'
  },
  {
    name: 'Dara Khosrowshahi',
    company: 'Expedia',
    email: 'dara.khosrowshahi@expedia.com'
  },
  {
    name: 'Whitney Wolfe Herd',
    company: 'Bumble',
    email: 'whitney.herd@bumble.com'
  },
  {
    name: 'Payal Kadakia',
    company: 'ClassPass',
    email: 'payal.kadakia@classpass.com'
  },
  {
    name: 'Emily Weiss',
    company: 'Glossier',
    email: 'emily.weiss@glossier.com'
  },
  {
    name: 'Viktor Svekolin',
    company: ' Lanka Innovation Technologies',
    email: ' viktor@lit.com'
  },
  {
    name: 'Alexey Winodelov',
    company: ' Smol Winery',
    email: ' alex@smol.wine'
  },
  {
    name: 'Hunter S. Thompson',
    company: 'Rolling Stone',
    email: 'hunter.thompson@rollingstone.com'
  },
  {
    name: 'William S. Burroughs',
    company: 'Grove Press',
    email: 'william.burroughs@grovepress.com'
  },
  {
    name: 'Jack Kerouac',
    company: 'Viking Press',
    email: 'jack.kerouac@vikingpress.com'
  },
  {
    name: 'Allen Ginsberg',
    company: 'City Lights Books',
    email: 'allen.ginsberg@citylights.com'
  },
  {
    name: 'Kurt Vonnegut',
    company: 'Delacorte Press',
    email: 'kurt.vonnegut@delacorte.com'
  },
  {
    name: 'Charles Bukowski',
    company: 'Black Sparrow Press',
    email: 'charles.bukowski@blacksparrow.com'
  },
  {
    name: 'Tony Stark',
    company: 'Stark Industries',
    email: 'tony.stark@starkindustries.com'
  },
  {
    name: 'Bruce Wayne',
    company: 'Wayne Enterprises',
    email: 'bruce.wayne@wayneenterprises.com'
  },
  {
    name: 'Peter Parker',
    company: 'Daily Bugle',
    email: 'peter.parker@dailybugle.com'
  },
  {
    name: 'Reed Richards',
    company: 'Fantastic Four Inc.',
    email: 'reed.richards@fantasticfour.com'
  },
  {
    name: 'Neo',
    company: 'The Matrix',
    email: 'neo@thematrix.com'
  },
  {
    name: 'Kaylee Frye',
    company: 'Serenity',
    email: 'kaylee.frye@serenity.com'
  },
  {
    name: 'Dexter Morgan',
    company: 'Miami Metro Police Department',
    email: 'dexter.morgan@miamimetropd.com'
  },
  {
    name: 'Willow Rosenberg',
    company: 'Sunnydale High School',
    email: 'willow.rosenberg@sunnydalehigh.edu'
  },
  {
    name: 'Penelope Garcia',
    company: 'FBI',
    email: 'penelope.garcia@fbi.gov'
  },
  {
    name: 'Richard Hendricks',
    company: 'Pied Piper',
    email: 'richard.hendricks@piedpiper.com'
  },
  {
    name: 'Sheldon Cooper',
    company: 'Caltech',
    email: 'sheldon.cooper@caltech.edu'
  },
  {
    name: 'Cisco Ramon',
    company: 'Star Labs',
    email: 'cisco.ramon@starlabs.com'
  },
  {
    name: 'Felicity Smoak',
    company: 'Queen Consolidated',
    email: 'felicity.smoak@queenconsolidated.com'
  },
  {
    name: 'Elliot Alderson',
    company: 'Allsafe Cybersecurity',
    email: 'elliot.alderson@allsafe.com'
  },
  {
    name: 'J.A.R.V.I.S.',
    company: 'Stark Industries',
    email: 'jarvis@starkindustries.com'
  },
  {
    name: 'Tron',
    company: 'The Grid',
    email: 'tron@thegrid.com'
  }
]
