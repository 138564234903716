import { useMemo, useState } from 'react'

const initSet = <T>(): Set<T> => new Set<T>()

export type SetActions<T> = {
  add(id: T): SetActions<T>
  has(id: T): boolean
  toggle(id: T): SetActions<T>
  remove(id: T): SetActions<T>
  clear(): SetActions<T>
}

// We hide some setters from the returned map to disable autocompletion
type Return<T> = [Omit<Set<T>, 'set' | 'clear' | 'delete'>, SetActions<T>]

export function useSet<T>(initialSet?: Set<T> | T[]): Return<T> {
  const [set, setSet] = useState(
    initialSet ? (Array.isArray(initialSet) ? new Set(initialSet) : initialSet) : <() => Set<T>>initSet
  )

  const utils = useMemo<SetActions<T>>(() => {
    const utils: SetActions<T> = {
      add(id) {
        setSet(prev => {
          const newSet = new Set<T>()

          // we don't use Array.from inside new Set() just not to go through the set twice
          prev.forEach(id => newSet.add(id))
          newSet.add(id)

          return newSet
        })

        return utils
      },
      has(id) {
        return set.has(id)
      },
      toggle(id) {
        if (utils.has(id)) {
          utils.remove(id)

          return utils
        } else {
          return utils.add(id)
        }
      },
      remove(id: T) {
        setSet(prev => {
          const newSet = new Set<T>()

          prev.forEach(prevId => id !== prevId && newSet.add(prevId))

          return newSet
        })

        return utils
      },
      clear() {
        setSet(initSet)

        return utils
      }
    }

    return utils
  }, [set])

  return [set, utils]
}
