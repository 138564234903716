import { BreakpointContext, getBreakpoint } from 'hooks'
import React, { useState, type PropsWithChildren, type ReactNode, useEffect } from 'react'
import type { BreakpointName } from 'shared/constants'

export function BreakpointProvider(props: PropsWithChildren): ReactNode {
  const [breakpoint, setBreakpoint] = useState<BreakpointName>(getBreakpoint(window.innerWidth))

  useEffect(() => {
    function onScreenResize(): void {
      const currentBreakpoint = getBreakpoint(window.innerWidth)
      setBreakpoint(currentBreakpoint)
    }

    window.addEventListener('resize', onScreenResize)
    return () => window.removeEventListener('resize', onScreenResize)
  }, [])

  return <BreakpointContext.Provider value={breakpoint}>{props.children}</BreakpointContext.Provider>
}
