export enum Breakpoints {
  SM = 320,
  MD = 768,
  LG = 1024,
  XL = 1440
}

export const enum BreakpointName {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl'
}
