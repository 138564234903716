import './static/styles/global.css'
import React, { ReactNode } from 'react'
import { BreakpointProvider } from 'providers'
import { GatsbyBrowser } from 'gatsby'

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = function () {
  window.scrollTo(0, 0)
  return false
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = function ({ element }) {
  return <BreakpointProvider>{element}</BreakpointProvider>
}
