export enum ServiceIcon {
  ProductPrototyping = 'ProductPrototyping',
  MVPDesign = 'MVPDesign',
  ProductRedesign = 'ProductRedesign',
  DesignSystemSupport = 'DesignSystemSupport',
  DesignAudit = 'DesignAudit',
  PWA = 'PWA',
  LandingPage = 'LandingPage',
  Applications = 'Applications',
  AppWithWebRTCWebSockets = 'AppWithWebRTCWebSockets',
  B2BProducts = 'B2BProducts',
  B2CProducts = 'B2CProducts',
  DatabaseManagement = 'DatabaseManagement',
  ThirdPartyIntegrations = 'ThirdPartyIntegrations',
  PerformanceOptimization = 'PerformanceOptimization',
  APIDevelopment = 'APIDevelopment',
  ContainerizationAndOrchestration = 'ContainerizationAndOrchestration',
  AutomatedTestingAndCICD = 'AutomatedTestingAndCICD',
  PWAApplications = 'PWAApplications',
  StaticWebpages = 'StaticWebpages',
  AIChatbotsandVirtualAssistants = 'AIChatbotsandVirtualAssistants',
  FraudDetectionAndSecurity = 'FraudDetectionAndSecurity',
  ComputerVision = 'ComputerVision',
  DataAnalysis = 'DataAnalysis',
  TrendPrediction = 'TrendPrediction',
  TechnicalAudit = 'TechnicalAudit',
  SecurityCheck = 'SecurityCheck',
  OperationalEvaluation = 'OperationalEvaluation',
  Compliance = 'Compliance',
  FinancialHealthAssessment = 'FinancialHealthAssessment',
  CMSConnection = 'CMSConnection',
  ECommerce = 'ECommerce',
  WebsiteBuilders = 'WebsiteBuilders',
  DataVisualization = 'DataVisualization'
}

export enum ServiceId {
  Design = 'design',
  FullCycle = 'full-cycle',
  Frontend = 'frontend',
  Backend = 'backend',
  MachineLearning = 'machine-learning',
  ProductAudit = 'product-audit'
}

export type ServiceData = {
  title: string
  path: string
  tabs: Array<{
    icon: ServiceIcon
    title: string
  }>
}
export const servicesInfo: Record<ServiceId, ServiceData> = {
  [ServiceId.Design]: {
    title: 'UX/UI Design',
    path: '/services/design',
    tabs: [
      {
        icon: ServiceIcon.ProductPrototyping,
        title: 'Product Prototyping'
      },
      {
        icon: ServiceIcon.MVPDesign,
        title: 'MVP Design'
      },
      {
        icon: ServiceIcon.ProductRedesign,
        title: 'Product Redesign'
      },
      {
        icon: ServiceIcon.DesignSystemSupport,
        title: 'Design System Support'
      },
      {
        icon: ServiceIcon.DesignAudit,
        title: 'Design Audit'
      }
    ]
  },
  [ServiceId.FullCycle]: {
    title: 'Full Cycle Product Development',
    path: '/services/full-cycle',
    tabs: [
      {
        icon: ServiceIcon.PWA,
        title: 'PWA'
      },
      {
        icon: ServiceIcon.LandingPage,
        title: 'Landing Page'
      },
      {
        icon: ServiceIcon.Applications,
        title: 'Applications'
      },
      {
        icon: ServiceIcon.AppWithWebRTCWebSockets,
        title: 'App with WebRTC/WebSockets'
      },
      {
        icon: ServiceIcon.B2BProducts,
        title: 'B2B Products'
      },
      {
        icon: ServiceIcon.B2CProducts,
        title: 'B2C Products'
      }
    ]
  },
  [ServiceId.Backend]: {
    title: 'Backend',
    path: '/services/backend',
    tabs: [
      {
        icon: ServiceIcon.DatabaseManagement,
        title: 'Database Management'
      },
      {
        icon: ServiceIcon.ThirdPartyIntegrations,
        title: '3rd Party Integrations'
      },
      {
        icon: ServiceIcon.PerformanceOptimization,
        title: 'Performance Optimization'
      },
      {
        icon: ServiceIcon.APIDevelopment,
        title: 'API Development'
      },
      {
        icon: ServiceIcon.ContainerizationAndOrchestration,
        title: 'Containerization and Orchestration'
      },
      {
        icon: ServiceIcon.AutomatedTestingAndCICD,
        title: 'Automated Testing and Continuous Integration/Deployment'
      }
    ]
  },
  [ServiceId.Frontend]: {
    title: 'Frontend',
    path: '/services/frontend',
    tabs: [
      {
        icon: ServiceIcon.PWAApplications,
        title: 'PWA Applications'
      },
      {
        icon: ServiceIcon.StaticWebpages,
        title: 'Static Webpages'
      },
      {
        icon: ServiceIcon.CMSConnection,
        title: 'CMS Connection'
      },
      {
        icon: ServiceIcon.ECommerce,
        title: 'eCommerce'
      },
      {
        icon: ServiceIcon.WebsiteBuilders,
        title: 'Website Builders'
      },
      {
        icon: ServiceIcon.DataVisualization,
        title: 'Data Visualization'
      }
    ]
  },
  [ServiceId.MachineLearning]: {
    title: 'Machine Learning',
    path: '/services/machine-learning',
    tabs: [
      {
        icon: ServiceIcon.AIChatbotsandVirtualAssistants,
        title: 'AI Chatbots and Virtual Assistants'
      },
      {
        icon: ServiceIcon.FraudDetectionAndSecurity,
        title: 'Fraud Detection and Security'
      },
      {
        icon: ServiceIcon.ComputerVision,
        title: 'Computer Vision'
      },
      {
        icon: ServiceIcon.DataAnalysis,
        title: 'Data Analysis'
      },
      {
        icon: ServiceIcon.TrendPrediction,
        title: 'Trend Prediction'
      }
    ]
  },
  [ServiceId.ProductAudit]: {
    title: 'Product Audit',
    path: '/services/product-audit',
    tabs: [
      {
        icon: ServiceIcon.TechnicalAudit,
        title: 'Technical Audit'
      },
      {
        icon: ServiceIcon.SecurityCheck,
        title: 'Security Check'
      },
      {
        icon: ServiceIcon.OperationalEvaluation,
        title: 'Operational Evaluation'
      },
      {
        icon: ServiceIcon.Compliance,
        title: 'Compliance'
      },
      {
        icon: ServiceIcon.FinancialHealthAssessment,
        title: 'Financial Health Assessment'
      }
    ]
  }
} as const
