import { createContext, useContext } from 'react'
import { Breakpoints, BreakpointName } from 'shared/constants'
export const BreakpointContext = createContext<BreakpointName>(BreakpointName.XL)

export const useBreakpoints = (): BreakpointName => {
  return useContext(BreakpointContext)
}

export function getBreakpoint(windowSize: number): BreakpointName {
  // let resultBreakpoint: BreakpointName = BreakpointName.SM

  // for (const name in breakpoints) {
  //   const size = breakpoints[name as BreakpointName] as number
  //   const resultBreakpointSize = breakpoints[resultBreakpoint] as number
  //   const resultBreakpointDiff = Math.abs(resultBreakpointSize - windowSize)
  //   const breakpointDiff = Math.abs(size - windowSize)

  //   if (breakpointDiff < resultBreakpointDiff) resultBreakpoint = name as BreakpointName
  // }

  // return resultBreakpoint
  return Object.entries(breakpoints).reduce((selectedBp, [bp, size]): BreakpointName => {
    const diff = size - windowSize
    if (diff <= 0) return bp as BreakpointName

    const selectedBpDiff = breakpoints[selectedBp] - windowSize
    if (diff < selectedBpDiff) return bp as BreakpointName

    return selectedBp
  }, BreakpointName.SM as BreakpointName)
}

const breakpoints = {
  sm: Breakpoints.SM,
  md: Breakpoints.MD,
  lg: Breakpoints.LG,
  xl: Breakpoints.XL
} as const
